import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import React, {useState} from "react";
import * as styles from './styles.module.scss';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { navigate } from "gatsby";

function WiZRXManual(){
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const checkPassword = () => {
      event.preventDefault();
      if (password === '123') {
        setIsAuthorized(true);
      } else {
        navigate('/'); 
      }
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        checkPassword();
      }
    };
  
    if (!isAuthorized) {
      return (
        <div className={styles.passwordOverlay}>
          {/* <h1 className={styles.passwordTitle}>Welcome to our <br/><span>Onboarding Program</span></h1> */}
          <div className={styles.passwordPrompt}>
            <p className={styles.passwordTitle}>Welcome to our <br/><span>onboarding program</span></p>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter password"
            />
            <button className={styles.showhidepsw} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <AiFillEye/> : <AiFillEyeInvisible />}
            </button>
            <button className={styles.submitPassword} onClick={checkPassword}>Submit</button>
          </div>
        </div>
      );
    }
    return(
        <>
        <Header/>
        <ManualContainer iframe={true} iframeUrl={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1713991486/age_mere_5_qwsaoj.jpg"}/>
        </>
    );
}
export default WiZRXManual;