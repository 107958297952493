import React, { useState, useEffect } from "react";
import FullScreen from "../../../svg/fullscreen.svg";
import ExitFullScreen from "../../../svg/exitfullscreen.svg";

function FullScreenToggle() {
  const [state, setState] = useState(false);

  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (state) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setState(!state);
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleFullScreenChange = () => {
        setState(document.fullscreen);
      }
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      return () => {
        document.removeEventListener("fullscreenchange", handleFullScreenChange);
      }
    }
  }, []);

  return (
    <button  aria-label="" onClick={toggleFullScreen}>
      {state ? <ExitFullScreen /> : <FullScreen />}
    </button>
  );
}

export default FullScreenToggle;
