import React, { useState, useEffect } from "react";
import * as styles from "./ManualContainer.module.scss";
import FullScreenToggle from "../FullScreenToggle/FullScreenToggle";
import { navigate } from "gatsby";
import Menu from "../../../images/menu.svg";
import Arrow from "../../../images/menuArrow.svg";

function ManualContainer({ image, iframe, iframeUrl }) {
  const [state, setState] = useState(false);
  const [scale, setScale] = useState(1000);
  const [menuVisible, setMenuVisible] = useState(true);
  const [visibleItems, setVisibleItems] = useState(5);
  const [loadedItems, setLoadedItems] = useState([]);
  const [isFullyExpanded, setIsFullyExpanded] = useState(false);
  const [isContracting, setIsContracting] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const zoomIn = () => {
    if (scale <= 1300) {
      setScale(scale + 100);
    }
  };

  const zoomOut = () => {
    if (scale >= 700) {
      setScale(scale - 100);
    }
  };

  const toggleItems = () => {
    const increment = 5;
    if (!isFullyExpanded) {
      const newVisibleItems = visibleItems + increment;
      if (newVisibleItems >= menuItems.length) {
        setVisibleItems(menuItems.length);
        setIsFullyExpanded(true);
        setIsContracting(false);
      } else {
        setVisibleItems(newVisibleItems);
        setIsContracting(false);
      }
    } else {
      const decrement = 5;
      const newVisibleItems = visibleItems - decrement;
      if (newVisibleItems <= 5) {
        setVisibleItems(5);
        setIsContracting(false);
        setIsFullyExpanded(false);
      } else {
        // More items can still be hidden
        setVisibleItems(newVisibleItems);
        setIsContracting(true);
      }
    }
  };

  useEffect(() => {
    setLoadedItems(menuItems.slice(0, visibleItems));
  }, [visibleItems]);

  const menuItems = [
    {
      href: "https://library.masterwizr.com/sharing/shared_6613e532d2fa1d6f2e90101b-open/folderview",
      text: "Welcome",
    },
    {
      href: "https://studio.masterwizr.com/share/shared-6627c55dda1536236648c064-public",
      text: "Promo videos",
    },
    {
      href: "https://studio.masterwizr.com/share/shared-6628d4ce9ad786a3f611d9ae-public",
      text: "Tutorials microsite",
    },
    {
      href: "https://library.masterwizr.com/sharing/shared_662baf949ad786a3f6124df0-open/streaming",
      text: "Portal for sales",
    },
  {
    href: "https://studio.masterwizr.com/share/shared-6614667427248e3956ebe9a8-public",
    text: "Portal highlights"
  },
  {
    href: "https://studio.masterwizr.com/share/shared-651b17d6880e12faa02421ab-public",
    text: "Product presentations"
  },
    {
      href: "https://studio.masterwizr.com/share/shared-6543b6801b0f3f1808f85da1-public",
      text: "Videos from CEO",
    },
    {
      href: "https://studio.masterwizr.com/share/shared-662ae06c9ad786a3f61229c4-public",
      text: "Brochures",
    },
    {
      href: "https://library.masterwizr.com/sharing/shared_66292ba29ad786a3f611ee21-open/folderview",
      text: "Essential documents",
    },
    {
      href: "https://www.masterwizr.no/user-cases/",
      text: "Website - explainer videos",
    },
    {
      href: "https://publisher.masterwizr.com/share/8e9cfec2-77b0-418e-984f-5d8b78330272",
      text: "Pictures",
    },
    {
      href: "https://library.masterwizr.com/sharing/shared_6628df4e9ad786a3f611dcdf-open/streaming",
      text: "Community portal",
    },
    // {
    //   href: "https://library.masterwizr.com/sharing/shared_6628df4e9ad786a3f611dcdf-open/streaming",
    //   text: "Community portal",
    // },
  ];

  return (
    <div className={styles.manualContainer}>
      <button onClick={toggleMenu} className={styles.menuButton}>
        Open Menu
      </button>
      <div className={styles.btns}>
        <button className={styles.zoomout} onClick={zoomOut}>
          -
        </button>
        <button onClick={zoomIn}>+</button>
        <FullScreenToggle />
        <div className={styles.menuIcon} onClick={toggleMenu}>
          <Menu />
        </div>
      </div>
      {iframe ? (
        <div
          className={`${styles.frameContainer} ${
            menuVisible ? styles.show : styles.hide
          }`}
        >
          <div
            style={{ width: scale }}
            className={
              state
                ? `${styles.imageContainer} ${styles.smallerContainer}`
                : styles.imageContainer
            }
          >
            <img src={iframeUrl} alt="manual" />
          </div>
          <div className={styles.menu}>
            <ol className={styles.mainList}>
              {loadedItems.map((item, index) => (
                <li
                  key={index}
                  className={`${styles.listTitle} ${styles.isVisible}`}
                >
                  <a href={item.href} target="_blank">
                    {item.text}
                  </a>
                </li>
              ))}
              <button
                onClick={toggleItems}
                className={`${styles.loadMoreButton} ${
                  isContracting ? styles.reverseMode : ""
                }`}
              >
                <Arrow />
              </button>
            </ol>
          </div>
        </div>
      ) : (
        <div
          style={{ width: scale }}
          className={
            state
              ? `${styles.imageContainer} ${styles.smallerContainer}`
              : styles.imageContainer
          }
        >
          <img src={image} alt="manual" />
        </div>
      )}
    </div>
  );
}

export default ManualContainer;
