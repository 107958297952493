// extracted by mini-css-extract-plugin
export var activetab = "ManualContainer-module--activetab--c0f2c";
export var btns = "ManualContainer-module--btns--5f0a6";
export var frameContainer = "ManualContainer-module--frameContainer--588fd";
export var gobackRec = "ManualContainer-module--gobackRec--c01c5";
export var hide = "ManualContainer-module--hide--8e376";
export var imageContainer = "ManualContainer-module--imageContainer--c68ab";
export var listTitle = "ManualContainer-module--listTitle--3de3b";
export var loadMoreButton = "ManualContainer-module--loadMoreButton--f3e2b";
export var mainList = "ManualContainer-module--mainList--a1168";
export var manualContainer = "ManualContainer-module--manualContainer--3b797";
export var manualTabs = "ManualContainer-module--manual-tabs--67308";
export var menu = "ManualContainer-module--menu--2a028";
export var menuClosed = "ManualContainer-module--menuClosed--5b6c0";
export var menuIcon = "ManualContainer-module--menuIcon--b9e55";
export var menuOpen = "ManualContainer-module--menuOpen--cd48f";
export var menuToggle = "ManualContainer-module--menuToggle--da897";
export var recLink2 = "ManualContainer-module--recLink2--1d845";
export var reverseMode = "ManualContainer-module--reverseMode--979c2";
export var scaleValue = "ManualContainer-module--scaleValue--3b90e";
export var show = "ManualContainer-module--show--6c17d";
export var smallerContainer = "ManualContainer-module--smallerContainer--91d34";
export var studiotab = "ManualContainer-module--studiotab--333e2";
export var zoomout = "ManualContainer-module--zoomout--3cf9d";